import React from "react";
import { graphql } from "gatsby";

// Layout Imports
import Default from '../../layouts/Default/Default';
import SEO from '../../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../../components/shared/PageHeader/PageHeader';
import Section from '../../components/shared/Section/Section';
import TagList from '../../components/shared/TagList/TagList';

const TagsPage = ({
  data: {allMdx: {group}},
  ...props
}) => {
  return (
    <Default
      location={props.location}
      title='All Tags'
    >
      <SEO
        title='All Tags'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading='All Tags'
      />

      <Section>
        {group && group.length && (
          <TagList
            tags={group}
            count
            variant='large'
          />
        )}
      </Section>
    </Default>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`